import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';

// ?preferred_variant=BUYBOX_SORTING_EXP:control
// ?preferred_variant=BUYBOX_SORTING_EXP:variant_1
// ?preferred_variant=BUYBOX_SORTING_EXP:variant_2

// ?remove_preferred_experiment=POPULAR_EXP

export const BuyBoxSortingExpName = 'BUYBOX_SORTING_EXP' as const;

export type BuyBoxSortingExpVariants = ToVariants<typeof BuyBoxSortingExpVariants>;

export const BuyBoxSortingExpVariants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
	VARIANT_2: 'variant_2',
} as const;

export default generate(BuyBoxSortingExpName, BuyBoxSortingExpVariants);
