import * as Types from '../../../../../@types/graphql-types';

import gql from 'graphql-tag';
export type UpdateTextRecommendationMutationVariables = Types.Exact<{
	input: Types.UpdateTextRecommendationInput;
}>;

export type UpdateTextRecommendationMutation = {
	__typename?: 'Mutation';
	updateTextRecommendation: { __typename?: 'UpdateTextRecommendationPayload'; id: string };
};

export const UpdateTextRecommendationDocument = gql`
	mutation UpdateTextRecommendation($input: UpdateTextRecommendationInput!) {
		updateTextRecommendation(input: $input) {
			id
		}
	}
`;
