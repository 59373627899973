import * as Types from '../../../../../@types/graphql-types';

import gql from 'graphql-tag';
export type DeleteTextRecommendationMutationVariables = Types.Exact<{
	input: Types.DeleteTextRecommendationInput;
}>;

export type DeleteTextRecommendationMutation = {
	__typename?: 'Mutation';
	deleteTextRecommendation: { __typename?: 'DeleteTextRecommendationPayload'; id: string };
};

export const DeleteTextRecommendationDocument = gql`
	mutation DeleteTextRecommendation($input: DeleteTextRecommendationInput!) {
		deleteTextRecommendation(input: $input) {
			id
		}
	}
`;
