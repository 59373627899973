import * as Types from '../../../../../@types/graphql-types';

import gql from 'graphql-tag';
export type CreateRecommendationMutationVariables = Types.Exact<{
	input: Types.CreateRecommendationInput;
}>;

export type CreateRecommendationMutation = {
	__typename?: 'Mutation';
	createRecommendation: { __typename?: 'CreateRecommendationPayload'; id: string };
};

export const CreateRecommendationDocument = gql`
	mutation CreateRecommendation($input: CreateRecommendationInput!) {
		createRecommendation(input: $input) {
			id
		}
	}
`;
