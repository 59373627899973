import { render, staticRenderFns } from "./ProviderPromotionOffer.vue?vue&type=template&id=ff4f402e&scoped=true"
import script from "./ProviderPromotionOffer.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProviderPromotionOffer.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ProviderPromotionOffer.vue?vue&type=style&index=0&id=ff4f402e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff4f402e",
  null
  
)

export default component.exports