import { getVm } from '@/helpers/vm-helper';
import { ExperimentTrackEventOptions } from '../Core/types';
import FTSwiperExp, { FTSwiperExpName, FTSwiperExpVariants } from '.';

export const trackFreeTrialSwiperExp = (
	TrackingOptions: ExperimentTrackEventOptions<FTSwiperExpVariants>,
	lastRoute = false
) => {
	const vm = getVm();
	const activeVariant = vm?.$store.getters['experiment/activeVariantsWithControlGroup'][FTSwiperExpName] ?? null;
	const isUserLoggedIN = !!vm?.$store.getters['user/isLoggedIn'];
	const lastRouteName = vm?.$store.state.routing.lastRoute?.name;

	const routeName = lastRoute ? lastRouteName : vm?.$route.name;
	if (activeVariant && ['app.detail.movies', 'app.detail.show'].includes(routeName) && !isUserLoggedIN) {
		FTSwiperExp.trackEvent({
			property: 'yo_swiper',
			...TrackingOptions,
		});
	}
};

export const amzTagByVariant = {
	[FTSwiperExpVariants.CONTROL]: 'amazon-gb-ft-exp-swc-21',
	[FTSwiperExpVariants.VARIANT_1]: 'amazon-gb-ft-exp-sw1-21',
	[FTSwiperExpVariants.VARIANT_2]: 'amazon-gb-ft-exp-sw2-21',
	[FTSwiperExpVariants.VARIANT_3]: 'amazon-gb-ft-exp-sw3-21',
};

export const appleTVSuffixTagByVariant = {
	[FTSwiperExpVariants.CONTROL]: '-sw',
	[FTSwiperExpVariants.VARIANT_1]: '-sw1',
	[FTSwiperExpVariants.VARIANT_2]: '-sw2',
	[FTSwiperExpVariants.VARIANT_3]: '-sw3',
};
