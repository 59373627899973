import { MonetizationType } from '@/@types/graphql-types';
import { TitleOfferFragment } from '@/components/buybox/graphql/fragments/Offer.fragment';
import { useConstantStore, useLanguageStore, useUserStore } from '@/helpers/composables/useStores';
import { formattedReleaseDate } from '@/helpers/date-helper';
import { BuyBoxFilterOptions } from '@/stores/user.store';
import { computed } from 'vue';
import { allowedLanguagesUpcomingReleases } from '@/components/buybox/utils/upcoming-helpers';
import { TitleDetail } from '@/interfaces/title-details-graphql';
import { MaybeRefOrGetter, toValue } from '@vueuse/core';
import { GetBuyBoxOffersQuery } from '../graphql/GetBuyBoxOffers.query';
import { AssertByTypename } from '@/helpers/graphql-model-helper';
import { buyboxFilterByMonetizationType, sortedPackageIds } from '../new-buybox-config';
import type { UpcomingRelease } from '../types';
import { excludePackages } from '@/constants/buybox-excluded-packages.constant';
import { includeGenericReleases } from '../upcomingBuybox/utils';

const AVOD_TECHNICAL_NAMES = {
	ACTIVE: ['plex', 'justwatchplexchannel'],
	INACTIVE: ['plexplayer', 'justwatchplexchannel'],
};

const sortingByFilterOption: Record<string, (a: TitleOfferFragment, b: TitleOfferFragment) => number> = {
	[BuyBoxFilterOptions.SUBSCRIPTION]: (offerA: TitleOfferFragment, offerB: TitleOfferFragment) => {
		const packageAId = String(offerA.package.packageId) as keyof typeof sortedPackageIds;
		const packageBId = String(offerB.package.packageId) as keyof typeof sortedPackageIds;

		const offerFreeTrialA = sortedPackageIds?.[packageAId]?.free_trial;
		const offerFreeTrialB = sortedPackageIds?.[packageBId]?.free_trial;

		const offerPriceA = sortedPackageIds?.[packageAId]?.price;
		const offerPriceB = sortedPackageIds?.[packageBId]?.price;

		// Sorts array by two dimensions - first the longest free trial length, then the cheapest price.
		return Number(offerFreeTrialB) - Number(offerFreeTrialA) || Number(offerPriceA) - Number(offerPriceB);
	},
	[BuyBoxFilterOptions.FREE]: (offerA: TitleOfferFragment, offerB: TitleOfferFragment) => {
		const isJustWatchTV = (offer: TitleOfferFragment) => offer.package.technicalName === 'justwatchtv';
		return isJustWatchTV(offerB) ? 1 : isJustWatchTV(offerA) ? -1 : 0;
	},
};

interface BuyBoxDataProps {
	offers: MaybeRefOrGetter<{
		stream: TitleOfferFragment[];
		rent: TitleOfferFragment[];
		buy: TitleOfferFragment[];
		free: TitleOfferFragment[];
		fast: TitleOfferFragment[];
		cinema: TitleOfferFragment[];
		bundles: AssertByTypename<NonNullable<GetBuyBoxOffersQuery['node']>, 'Movie'>['bundles'];
		promotedOffers: TitleOfferFragment[];
	}>;
	isAVODActive: MaybeRefOrGetter<boolean>;
	title: MaybeRefOrGetter<TitleDetail>;
	buyboxFilter: MaybeRefOrGetter<BuyBoxFilterOptions>;
	sortingType: MaybeRefOrGetter<'default' | 'v1' | 'v2' | null>;
}

/**
 * Main composable for managing BuyBox data and filtering offers
 * @param offers - All available offers grouped by type (stream, rent, buy, etc.)
 * @param isAVODActive - Flag indicating if AVOD is active
 * @param title - Title details object
 * @param buyboxFilter - Current filter option selected by user (buyboxFilter in user store or  )
 */
export function useBuyBoxData({ offers, isAVODActive, title, buyboxFilter, sortingType }: BuyBoxDataProps) {
	const { allProvidersByShortName } = useConstantStore();
	const { country, language } = useLanguageStore();
	const { isLoggedIn } = useUserStore();

	const currentBuyboxFilter = computed(() => toValue(buyboxFilter));
	const currentOffers = computed(() => toValue(offers));
	const currentTitle = computed(() => toValue(title));

	const offersByFilterOption = computed<Record<string, TitleOfferFragment[]>>(() => {
		const AVODTechnicalName = toValue(isAVODActive) ? AVOD_TECHNICAL_NAMES.ACTIVE : AVOD_TECHNICAL_NAMES.INACTIVE;

		//BUYBOX_SORTING_EXP
		const subscriptionOffers = [...currentOffers.value.stream].sort((a, b) =>
			sortingByFilterOption[BuyBoxFilterOptions.SUBSCRIPTION](a, b)
		);
		if (toValue(sortingType) && toValue(sortingType) !== 'default') {
			const sortedOffers: Record<string, string[]> =
				toValue(sortingType) === 'v1'
					? {
							DE: ['amp', 'dnp', 'atp'],
							US: ['amp', 'hlu', 'atp', 'dnp'],
					  }
					: {
							DE: ['atp', 'dnp', 'amp'],
							US: ['atp', 'hlu', 'amp', 'dnp'],
					  };
			subscriptionOffers.sort(
				(a, b) =>
					(sortedOffers[country.value].indexOf(a.package.shortName) + 1 || Number.MAX_SAFE_INTEGER) -
					(sortedOffers[country.value].indexOf(b.package.shortName) + 1 || Number.MAX_SAFE_INTEGER)
			);
		}

		//BUYBOX_SORTING_EXP

		return {
			[BuyBoxFilterOptions.SUBSCRIPTION]: subscriptionOffers,
			[BuyBoxFilterOptions.FREE]: [...currentOffers.value.free]
				.filter(el => !AVODTechnicalName.includes(el.package.technicalName))
				.sort((a, b) => sortingByFilterOption[BuyBoxFilterOptions.FREE](a, b)),
			[BuyBoxFilterOptions.FAST_TV]: currentOffers.value.fast,
			[BuyBoxFilterOptions.RENT]: currentOffers.value.rent,
			[BuyBoxFilterOptions.BUY]: currentOffers.value.buy,
			[BuyBoxFilterOptions.CINEMA]: currentOffers.value.cinema,
		};
	});

	const filteredRegularOffers = computed(() => {
		const bundles = currentOffers.value.bundles.map(bundle => ({
			...bundle.offer,
			standardWebURL: bundle.promotionUrl,
			bundleClearName: bundle.node.clearName,
			bundleId: bundle.node.bundleId,
			bundleTechnicalName: bundle.node.technicalName,
			icons: bundle.node.packages.map(pkg => pkg.iconWide),
		}));
		let regularOffers = [] as TitleOfferFragment[];
		if (currentBuyboxFilter.value === BuyBoxFilterOptions.ALL) {
			regularOffers = allRegularOffers.value.concat(bundles);
		} else if (currentBuyboxFilter.value === BuyBoxFilterOptions.SUBSCRIPTION) {
			regularOffers = offersByFilterOption.value[BuyBoxFilterOptions.SUBSCRIPTION].concat(bundles);
		} else {
			regularOffers = offersByFilterOption.value[currentBuyboxFilter.value];
		}

		return regularOffers;
	});

	// all regular offers (non-bundle offers)
	const allRegularOffers = computed<TitleOfferFragment[]>(() => {
		return Object.values(offersByFilterOption.value).flat();
	});

	const promotedOffers = computed(() => {
		return currentOffers.value.promotedOffers;
	});

	const excludedPackages = computed(() => excludePackages(isLoggedIn.value, currentTitle.value.id, country.value));

	const upcomingReleases = computed(() => {
		if (!allowedLanguagesUpcomingReleases.includes(language.value)) return [];

		const offersProviderShortNames = allRegularOffers.value.map(
			offer => offer.package.shortName + '-' + offer.monetizationType
		);

		const upcomingReleasesFiltered = currentTitle.value.content.upcomingReleases
			.map(
				release =>
					(!release.package
						? [release]
						: release.package.monetizationTypes.map(type => ({
								...release,
								package: {
									...release.package,
									monetizationTypes: [type],
									planOffers: type === MonetizationType.Flatrate ? release.package?.planOffers! : [],
								},
						  }))) as UpcomingRelease[]
			)
			.flat()
			.filter(release => {
				return (
					!!release.package &&
					!!allProvidersByShortName.value[release.package.shortName] &&
					!offersProviderShortNames.includes(
						release.package.shortName + '-' + release.package.monetizationTypes[0]
					) &&
					!excludedPackages.value.includes(release.package.packageId)
				);
			});

		const hasDigitalOffers =
			allRegularOffers.value.filter(offer => offer.monetizationType !== MonetizationType.Cinema).length > 0;
		const hasCinemaOffers =
			allRegularOffers.value.filter(offer => offer.monetizationType === MonetizationType.Cinema).length > 0;

		// if no upcoming digital releases, includes generic digital release. If no upcoming theatrical releases, includes generic theatrical release.
		return includeGenericReleases(currentTitle.value, upcomingReleasesFiltered, hasCinemaOffers, hasDigitalOffers);
	});

	const upcomingReleasesFromGroups = computed(() => {
		const uniqueReleases = new Set();
		const groupedReleases: Record<string, UpcomingRelease[]> = {};

		// Step 1: Group filtered releases
		upcomingReleases.value
			.filter(el => showUpcomingRelease(el, currentBuyboxFilter.value))
			.forEach((release, index) => {
				const uniqueKey = release.package
					? `${release.package.packageId}-${release.package.monetizationTypes[0]}`
					: index.toString();

				if (!uniqueReleases.has(uniqueKey)) {
					uniqueReleases.add(uniqueKey);
					const groupKey = buildGroupKey(release, index, language.value, country.value);
					if (!groupedReleases[groupKey]) {
						groupedReleases[groupKey] = [];
					}
					groupedReleases[groupKey].push(release);
				}
			});

		// Step 2: Transform and sort groups
		return (
			Object.values(groupedReleases)
				// Sort each group by monetization type
				// Transform into final format with group metadata
				.flatMap(group => {
					const allSameProviders = group.every(
						(release, _, releases) => release.package?.shortName === releases[0].package?.shortName
					);
					return group
						.sort((a, b) => {
							return (
								+isFlatrate(b.package?.monetizationTypes[0]) -
								+isFlatrate(a.package?.monetizationTypes[0])
							);
						})
						.map((item, index) => ({
							offer: item,
							isInGroup: group.length > 1,
							isLastOfGroup: group.length > 1 && index === group.length - 1,
							isFirst: index === 0,
							allSameProviders,
						}));
				})
		);
	});

	return {
		filteredRegularOffers,
		allRegularOffers,
		upcomingReleasesFromGroups,
		upcomingReleases,
		promotedOffers,
	};
}
function isFlatrate(type: MonetizationType | undefined) {
	return type === MonetizationType.Flatrate;
}

function showUpcomingRelease(release: UpcomingRelease, buyboxFilter: BuyBoxFilterOptions) {
	if (buyboxFilter === BuyBoxFilterOptions.ALL) return true;
	if (release.releaseType === 'THEATRICAL') return buyboxFilter === BuyBoxFilterOptions.CINEMA;

	const monetizationType = release.package?.monetizationTypes[0];
	return buyboxFilterByMonetizationType[buyboxFilter].includes(monetizationType as MonetizationType);
}
// Generate a unique key for grouping upcoming releases
function buildGroupKey(release: UpcomingRelease, index: number, language: string, country: string) {
	const releaseDate = release.releaseDate
		? formattedReleaseDate(release.releaseDate, release.label, language, 'short', country).heading
		: index.toString();

	return `${releaseDate}_${release.releaseType}`;
}
