import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';

// ?preferred_variant=FT_SWIPER_EXP:control
// ?preferred_variant=FT_SWIPER_EXP:variant_1
// ?preferred_variant=FT_SWIPER_EXP:variant_2
// ?preferred_variant=FT_SWIPER_EXP:variant_3

// ?remove_preferred_experiment=FT_SWIPER_EXP

export const FTSwiperExpName = 'FT_SWIPER_EXP' as const;

export type FTSwiperExpVariants = ToVariants<typeof FTSwiperExpVariants>;

export const FTSwiperExpVariants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
	VARIANT_2: 'variant_2',
	VARIANT_3: 'variant_3',
} as const;

export default generate(FTSwiperExpName, FTSwiperExpVariants);
